import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactInfo from "../components/contact-info"

export default function ImpressumPage() {
  return (
    <Layout className="no-sidebar">
      <SEO title="Impressum" />

      <div className="wrapper">
        <div className="container" id="main">
          <article id="content">
            <header>
              <h2>Impressum</h2>
            </header>

            <p>
              TRV Projektentwicklung UG<br/>
              Attilastrasse 18, 12529 Schönefeld<br/>
            </p>
            <ContactInfo />
            <p>
              Handelsregister: Cottbus, HRB 15432 CB<br/>
              Geschäftsführer: Dr. Karl-Michael Meiß<br/>
              Steuernummer: 049/121/06387<br/>
            </p>
            <p>
              Die Europäische Kommission hat unter http://ec.europa.eu/consumers/odr/ eine Europäische Online-Streitbeilegungsplattform eingerichtet. Diese Plattform kann ein Verbraucher für die außergerichtliche Beilegung einer Streitigkeit aus Online-Verträgen mit einem in der EU niedergelassenen Unternehmen nutzen. Hierzu muss er ein Online-Beschwerdeformular ausfüllen, das unter der genannten Adresse erreichbar ist.
            </p>
            <p>
              Wir weisen darauf hin, dass die nachfolgend benannte Stelle als Verbraucherschlichtungsstelle zuständig ist: Schlichtungsstelle bei der Deutschen Bundesbank, Postfach 11 12 32, 60047 Frankfurt am Main, www.bundesbank.de/schlichtungsstelle. Wir nehmen an einem Streitbeilegungsverfahren vor dieser Verbraucherschlichtungsstelle teil.
            </p>
          </article>
        </div>
      </div>
    </Layout>
  )
}
